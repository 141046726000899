import { Account } from "@/backend/models"
import { BaseRequest } from "@/lib/requests/BaseRequest"
import { AxiosResponse } from "axios"

export default class AccountShow extends BaseRequest<Account> {
  method = "GET"
  endpoint = "/api/account"

  processResponse(response: AxiosResponse) {
    return this.responseToObject(response, Account)
  }
}
